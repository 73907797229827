import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavRailItems } from '../../models/menu.interface';
import { environment } from './../../../environments/environment';
import { APP_CONFIG } from './../../../app/app.config';
import { HttpClient } from '@angular/common/http';
import { Apollo, ApolloBase } from 'apollo-angular';
import { GET_MENUS_QUERY } from './menus.static';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  public menus$ = new BehaviorSubject<NavRailItems[]>([]);
  private apollo!: ApolloBase;

  private appConfig = inject(APP_CONFIG);
  private http = inject(HttpClient);
  private apolloProvider = inject(Apollo);

  getNavigation(): void {
    if (!this.appConfig.useJson) {
      this.apollo = this.apolloProvider.use('cms');
      this.getApolloMenuData();
    }

    if (this.appConfig.useJson) {
      this.getJsonMenuData();
    }
  }

  getJsonMenuData(): void {
    this.http
      .get(
        `${environment.cdncms}${environment.version}/${this.appConfig.json['menu']}`
      )
      .subscribe({
        next: (data: any) => {
          this.menus$.next(this.organize(data?.data.menu.menuItems.nodes));
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  getApolloMenuData(): void {
    this.apollo
      .watchQuery({
        query: GET_MENUS_QUERY,
      })
      .valueChanges.pipe(take(1))
      .subscribe(({ data, error }: any) => {
        if (error) {
          console.error('error', error);
        }
        this.menus$.next(this.organize(data?.menu.menuItems.nodes));
      });
  }

  organize(nodes: any): NavRailItems[] {
    const nodesClone = JSON.parse(JSON.stringify(nodes));
    const menus: any[] = [];

    nodesClone.forEach((node: any) => {
      let icon = node.adsMenu.icon;
      // ToDo: Remove this when icon name is correct.
      // Temporary fix to replace erroneous icon name.
      if (icon === 'action--home') {
        node.adsMenu.icon = 'navigation--home';
      }

      if (icon === 'action--back') {
        node.adsMenu.icon = 'navigation--back';
      }

      this.setMenu(node, menus);
    });

    return menus;
  }

  setMenu(node: any, menus: any) {
    const menu = new NavRailItems(node);
    menus.push(menu);

    if (node.childItems.nodes.length) {
      menu.children = [];
      node.childItems.nodes.forEach((child: any) => {
        menu.children?.push(new NavRailItems(child));
      });
    }
  }
}
