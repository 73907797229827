<form class="search" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <ui-search
    class="icon-filter__search"
    [hasContent]="!!iconSearch.value"
    (clearSearch)="resetForm()"
  >
    <input
      matInput
      type="text"
      placeholder="Search application icons"
      formControlName="iconSearch"
    />
  </ui-search>
</form>
