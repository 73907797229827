import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from './../../../app.config';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CdnPageService {
  private data: null | any = null;
  private loaded = false;

  private appConfig = inject(APP_CONFIG);

  cdn(version: string): string {
    return `${environment.cdncms}${version}/${this.appConfig.json['page']}`;
  }

  async getPageContent(url: string) {
    if (!this.loaded) {
      try {
        const response = await fetch(this.cdn(environment.version));
        if (response.ok) {
          const data = await response.json();
          this.loaded = true;
          this.data = data;
        } else {
          console.error(`Fetch error`);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (this.data?.pages) {
      // Find page
      for (let i = 0; i < this.data.pages.length; i++) {
        if (this.data.pages[i].uri == url) {
          return this.data.pages[i];
        }
      }
    }
    return null;
  }
}
