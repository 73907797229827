export class AppIcon {
  public name: string;
  public files: Array<string>;
  public displayImgUrl!: string;

  constructor(data: any) {
    this.name = data?.name;
    const fileList = [];
    if (data?.files) {
      let hasDisplayImgUrl = false;

      for (let index = 0; index < data.files.length; index++) {
        // https://cdn.aveva.design//icons/applications/AI
        // const newURL = `${environment.cdn}/icons/applications/${this.name}/${data.files[index]}`;
        const newURL = `${data.files[index]}`;

        if (
          (newURL.includes('64') && !hasDisplayImgUrl) ||
          (newURL.includes('128') && !hasDisplayImgUrl) ||
          (newURL.includes('256') && !hasDisplayImgUrl)
        ) {
          hasDisplayImgUrl = true;
          this.displayImgUrl = newURL;
        }

        fileList.push(newURL);
      }
    }
    this.files = fileList;
  }
}
