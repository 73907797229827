import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { combineLatest } from 'rxjs';
import { AppIcon } from './../../models/appicon.interface';
import { FilterChangeArgs } from './../../models/filterChangeArgs.interface';
import { AppiconFilterPipe } from './../../pipes/appicon-filter/appicon-filter.pipe';
import { AppIconsService } from './../../services/icons/appicons.service';

@Component({
  selector: '[appAppIcons]',
  templateUrl: './icons-app.component.html',
  styleUrls: ['./icons-app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AppiconFilterPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppIconsComponent implements OnInit {
  @ViewChild('sidenav') sideNav!: MatSidenav;
  @ViewChild('icon') icon!: HTMLElement;
  public icons: Array<AppIcon> = new Array<AppIcon>();
  public error = false;
  public errorMsg = '';
  public showDetails: boolean = false;
  public filterArgs: FilterChangeArgs;
  public selectedIcon!: AppIcon;
  public selectedIconPreview!: string;
  public galleryReady: boolean = false;
  private defaultFilterArgs = {
    filter: '',
    category: '',
    iconstyle: '',
    size: '',
  };

  private cdr = inject(ChangeDetectorRef);

  constructor(
    public appIconService: AppIconsService,
    private appIconFilter: AppiconFilterPipe
  ) {
    this.filterArgs = new FilterChangeArgs(this.defaultFilterArgs);
  }

  ngOnInit(): void {
    let result = combineLatest([
      this.appIconService.vloading,
      this.appIconService.loading,
      this.appIconService.error,
    ]);
    result.subscribe(([iconsLoading, error]) => {
      if (error) {
        this.errorMsg = this.appIconService.errorMsg;
      } else {
        if (!iconsLoading) {
          this.icons = this.appIconService.icons;
          this.galleryReady = true;
        }
      }
      this.cdr.detectChanges();
    });
    this.appIconService.getAll();
    this.clearSelection();
  }

  getIcon(name: string): AppIcon | undefined {
    const icon = this.appIconService.icons.find((i) => i.name == name);
    return icon;
  }

  select(icon: AppIcon): void {
    if (icon) {
      const selected = this.getIcon(icon.name);
      if (selected) {
        this.selectedIcon = selected;
        this.selectedIconPreview = selected.displayImgUrl;
        this.showDetails = true;
        this.sideNav.open();
      }
    }
  }

  getIcons() {
    this.icons = [...this.appIconService.icons];
    this.icons = [
      ...this.appIconFilter.transform(
        [...this.appIconService.icons],
        this.filterArgs
      ),
    ];
    this.galleryReady = true;
  }

  FilterIcons(): void {
    this.galleryReady = false;
    this.getIcons();
  }

  FilterChange() {
    this.FilterIcons();
    this.sideNav.close();
    this.clearSelection();
    this.cdr.detectChanges();
  }

  clearSelection(): void {
    const emptyIcon = {
      name: '',
    };
    this.selectedIcon = new AppIcon(emptyIcon);
  }

  downloadZip() {}
}
