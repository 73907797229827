<div>
  <mat-chip-listbox #chips aria-label="Theme Selection" selectable>
    <mat-chip-option
      [highlighted]="lightTheme"
      (click)="select('light')"
      appearance="outline"
      selected
      >Light</mat-chip-option
    >
    <mat-chip-option
      [highlighted]="darkTheme"
      (click)="select('dark')"
      appearance="outline"
      >Dark</mat-chip-option
    >
    <mat-chip-option
      [highlighted]="interactive"
      (click)="select('interactive')"
      appearance="outline"
      >Interactive</mat-chip-option
    >
  </mat-chip-listbox>
  <div>
    <mat-checkbox class="checkbox" (change)="disable()" [checked]="disabled"
      >Disabled</mat-checkbox
    >
  </div>
</div>
