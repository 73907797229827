import { gql } from 'apollo-angular';
import { environment } from './../../../environments/environment';

export const GET_MENUS_QUERY = gql`{
            menu(id: "${environment.cms.menuRoot}") {
              name
              menuItems(where: {parentDatabaseId: 0}, first: 999) {
                nodes {
                  label
                  id
                  parentId
                  path
                  adsMenu
                  {
                    icon
                    isseparator
                  }
                  childItems(first: 999) {
                    nodes {
                      id
                      label
                      path
                      adsMenu
                      {
                        icon
                        isseparator
                      }
                    }
                  }
                }
              }
            }
          }
`;
