<h3>Icons by product group</h3>
<p>Download the PNG and ICO files in ZIP form by clicking the product below.</p>
<mat-card>
  <ul class="products">
    <li
      *ngFor="let product of products"
      class="item"
      (click)="download(product)"
    >
      <uilab-icon icon="action--download"></uilab-icon>
      <a class="product">{{ product }}</a>
    </li>
  </ul>
</mat-card>
