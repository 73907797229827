import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchResult } from './../../models/searchresult.interface';
import { environment } from './../../../environments/environment';
import { APP_CONFIG } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searching = new Subject<boolean>();
  public error: any;
  public results = new Subject<SearchResult>();

  private appConfig = inject(APP_CONFIG);

  async getPages() {
    try {
      let searchUrl = `${environment.cdncms}${environment.version}/${this.appConfig.json['search']}`;
      const response = await fetch(searchUrl);
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(`Error: ${err}`);
      return null;
    }
  }

  async searchContent(search: string, tag: any) {
    if (environment.version == 'preview') {
      this.error = 'Cannot search preview';
    } else {
      this.searching.next(true);
      this.error = '';
      const data = await this.getPages();
      if (data?.pages) {
        data.pages.forEach((page: any) => {
          const sr = new SearchResult(search, page, tag);
          if (sr.occurances > 0) {
            this.results.next(sr);
          }
        });
      }
    }
    this.searching.next(false);
  }
}
