import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FilterChangeArgs } from 'src/app/models/filterChangeArgs.interface';
import { AdsDestroyClass } from 'src/app/services/shared/destroy/destroy.service';

@Component({
  selector: 'app-appicon-filter',
  templateUrl: './appicon-filter.component.html',
  styleUrls: ['./appicon-filter.component.scss'],
})
export class AppiconFilterComponent extends AdsDestroyClass implements OnInit {
  public searchForm!: FormGroup;
  get iconSearch() {
    return this.searchForm.get('iconSearch') as FormControl;
  }

  @Input() args: FilterChangeArgs = new FilterChangeArgs();

  @Output() onFilterChange: EventEmitter<FilterChangeArgs> =
    new EventEmitter<FilterChangeArgs>();

  private formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      iconSearch: '',
    });
    this.iconSearch.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.initSearch();
      },
    });
  }

  resetForm(): void {
    this.iconSearch?.setValue('');
    this.iconSearch?.updateValueAndValidity();
    this.initSearch();
  }

  onSubmit(): void {
    this.initSearch();
  }

  FilterChange(args: FilterChangeArgs): void {
    this.args = args;
    this.onFilterChange.emit(this.args);
  }

  initSearch(): void {
    this.args.filter = this.iconSearch.value;
    this.onFilterChange.emit(this.args);
  }
}
