<mat-tab-group
  dynamicHeight
  preserveContent="true"
  animationDuration="0ms"
  [selectedIndex]="selectedIndex"
  (selectedIndexChange)="selectedIndexChange($event)"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab *ngFor="let page of pages" [label]="page.title">
    <div class="page-content">
      <app-inner-content
        *ngIf="page.type != 'ui'"
        [content]="setAbsoluteImagePaths(page.content)"
      ></app-inner-content>

      <section *ngIf="page.type == 'ui'">
        <app-inner-content [content]="page.content"></app-inner-content>

        <!-- <a>Not available.</a> -->

        <iframe
          *ngIf="page?.exampleUrl"
          class="externalContent"
          [src]="safeUrl(page.exampleUrl)"
        ></iframe>
      </section>
      <section appIcons *ngIf="page.type == 'icon-gallery'"></section>
      <section appIconProducts *ngIf="page.type == 'icon-products'"></section>
      <section appIconTracker *ngIf="page.type == 'icon-tracker'"></section>
      <section appAppIcons *ngIf="page.type == 'app-icon-gallery'"></section>
    </div>
  </mat-tab>
</mat-tab-group>
