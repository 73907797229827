<form
  class="search"
  #filterFor="ngForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="searchForm"
>
  <ui-search
    class="icon-filter__search"
    [hasContent]="!!iconSearch"
    (clearSearch)="resetForm()"
  >
    <input
      matInput
      type="text"
      placeholder="Search for icons"
      name="text"
      formControlName="iconSearch"
      (keyup)="onKeyUp()"
      (keydown)="onKeyDown()"
    />
  </ui-search>
</form>

<app-icon-chips
  class="iconGallery--filter"
  [filterOpts]="args"
  (onFilterChange)="FilterChange($event)"
>
</app-icon-chips>
